import React, { useCallback, useMemo, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Loader, Modal, SelectInput } from '@noloco/components';
import { ErrorText } from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { EXPERT } from '@noloco/core/src/constants/accountPlans';
import { UserTeam } from '@noloco/core/src/models/User';
import { PROJECT_QUERY_WITH_TEAM_STRING } from '@noloco/core/src/queries/core';
import { useSuccessAlert } from '@noloco/core/src/utils/hooks/useAlerts';
import { useDashboardAuth } from '@noloco/core/src/utils/hooks/useAuth';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import { UPDATE_PROJECT_TEAM } from '../queries/project';

const PROJECT_QUERY = gql`
  ${PROJECT_QUERY_WITH_TEAM_STRING}
`;

const TransferProject = () => {
  const {
    query: { projectId },
    push,
  } = useRouter();

  const { user } = useDashboardAuth();

  const { data: project } = useQuery(PROJECT_QUERY, {
    variables: {
      projectId: projectId,
    },
    fetchPolicy: 'network-only',
  });

  const successAlert = useSuccessAlert();
  const [error, setError] = useState(null);
  const [team, setTeam] = useState<UserTeam | null>(null);
  const [ownerId, setOwnerId] = useState<string | undefined>(undefined);
  const [updateProjectTeam, { loading }] = useMutation(UPDATE_PROJECT_TEAM);

  const previousTeamId = useMemo(() => {
    return project?.project?.team?.id;
  }, [project?.project]);

  const previousOwnerId = useMemo(() => {
    return project?.project?.creator.id;
  }, [project?.project]);

  const isValid = useMemo(() => {
    return team?.id && team.id !== previousTeamId;
  }, [team, previousTeamId]);

  const hasExpertPlan = useMemo(
    () => (project ? project.project?.team?.plan.type === EXPERT : false),
    [project],
  );
  const onSubmit = () => {
    setError(null);
    if (!team) {
      return;
    }
    updateProjectTeam({
      variables: {
        projectName: projectId,
        teamId: parseInt(team.id, 10),
        ownerId: hasExpertPlan ? ownerId : undefined,
      },
    })
      .then(({ data }) => {
        const updatedProject = data.updateProjectTeam;
        if (updatedProject) {
          successAlert(getText('transferProject.success'));
          push(`/apps/${team.id}`);
        } else {
          // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          setError(getText('newProject.errors.generic'));
        }
      })
      .catch((e) => {
        setError(e.message);
        console.log(e);
      });
  };

  const handleCancelOrBack = (event: any) => {
    event.preventDefault();
    push('/');
  };
  const ownerOptions = useMemo(() => {
    return (
      team?.users.map((u) => ({
        value: u.id,
        label: u.email,
      })) ?? []
    );
  }, [team]);
  const teamOptions = useMemo(() => {
    return (
      user?.teams.map((team) => ({
        value: team,
        label: team.name + (team.id === previousTeamId ? ' (Current)' : ''),
      })) ?? []
    );
  }, [previousTeamId, user?.teams]);

  const onChangeTeam = useCallback(
    (team: UserTeam) => {
      setTeam(team);
      setOwnerId(previousOwnerId);
    },
    [previousOwnerId],
  );
  return (
    <Modal
      canCancel={!loading}
      cancelText={getText('transferProject.cancel')}
      confirmDisabled={loading || !isValid}
      confirmText={getText('transferProject.confirm')}
      onCancel={handleCancelOrBack}
      onConfirm={onSubmit}
      title={
        <span className="text-2xl">{getText('transferProject.title')}</span>
      }
    >
      {!loading && (
        <div className="my-4 flex flex-col gap-2 text-base">
          <p className="text-sm text-gray-700">
            {getText('transferProject.chooseWorkspace')}
          </p>
          <SelectInput
            surface={LIGHT}
            value={team}
            onChange={onChangeTeam}
            options={teamOptions}
            placeholder={getText('transferProject.chooseWorkspacePlaceHolder')}
          />
          {hasExpertPlan && team && (
            <>
              <p className="text-sm text-gray-700">
                {getText('transferProject.expertPlan.chooseOwner')}
              </p>
              <SelectInput
                surface={LIGHT}
                value={ownerId}
                onChange={setOwnerId}
                options={ownerOptions}
                placeholder={getText(
                  'transferProject.expertPlan.chooseOwnerPlaceholder',
                )}
                allowClear
              />
            </>
          )}
        </div>
      )}
      <div className={'flex flex-col'}>
        {error && (
          <ErrorText className={'w-full py-4'}>
            {<span>Error: {error}</span>}
          </ErrorText>
        )}
        {loading && (
          <div className="text-brand w-full max-w-md px-8 py-4">
            <p className="text-sm">
              <div className="flex w-full flex-col items-center p-16">
                <Loader className="mx-auto" size="md" />
                <span className="mt-6 text-center">
                  {getText('transferProject.loading')}
                </span>
              </div>
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default TransferProject;
