import React, { Suspense, lazy, useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Box } from '@darraghmckay/tailwind-react-ui';
import {
  IconAlertTriangle,
  IconDotsVertical,
  IconExternalLink,
  IconPlus,
  IconUser,
} from '@tabler/icons-react';
import classNames from 'classnames';
import gql from 'graphql-tag';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import tailwindColors from 'tailwindcss/colors';
import { DefaultColors } from 'tailwindcss/types/generated/colors';
import {
  Button,
  Loader,
  Modal,
  Popover,
  TextInput,
  Tooltip,
  getColorShade,
} from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { EXPERT, FREE } from '@noloco/core/src/constants/accountPlans';
import { TEST_APPS } from '@noloco/core/src/constants/features';
import { baseColors } from '@noloco/core/src/constants/tailwindStyles';
import { ThemeSettings } from '@noloco/core/src/models/Project';
import {
  Project,
  Project as ProjectDefinition,
} from '@noloco/core/src/models/Project';
import { getPrimaryColorGroup } from '@noloco/core/src/utils/colors';
import { useDashboardAuth } from '@noloco/core/src/utils/hooks/useAuth';
import useCacheQuery from '@noloco/core/src/utils/hooks/useCacheQuery';
import useIsTrialExpired from '@noloco/core/src/utils/hooks/useIsTrialExpired';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import { getProjectDomain } from '@noloco/core/src/utils/pages';
import EmptyStateImg from '../img/undraw/progressive-app.svg';
import { DELETE_PROJECT } from '../queries/project';
import useTrackDashboardPage, {
  PageTypes,
} from '../utils/hooks/useTrackDashboardPage';
import FeatureLockedRoute from './FeatureLockedRoute';
import NewProject from './NewProject';
import TemplateList from './TemplateList';
import TrialBanner from './dashboard/TrialBanner';

const stopPropagation = (event: React.MouseEvent) => {
  event.stopPropagation();
  event.preventDefault();
};

const getProjectAcronym = (projectTitle: string): string =>
  upperFirst(
    projectTitle
      .split(/[- ]/g)
      .filter(Boolean)
      .slice(0, 2)
      .map((s) => s[0])
      .join('')
      .toLowerCase(),
  );

const getColorGroupFromTheme = (
  theme: ThemeSettings | undefined,
  index: number,
) => {
  if (!theme) {
    const colorIndex = index % baseColors.length;
    const colorName = baseColors[colorIndex] as keyof DefaultColors;
    return tailwindColors[colorName];
  }

  return getPrimaryColorGroup(theme);
};

const getGradientColorStops = (
  theme: ThemeSettings | undefined,
  index: number,
) => {
  const colorGroup = getColorGroupFromTheme(theme, index);
  return `linear-gradient(to right, ${colorGroup[400]}, ${colorGroup[600]})`;
};

type ProjectEntry = Pick<
  Project,
  'id' | 'name' | 'domains' | 'settings' | 'creator' | 'team'
>;

const PROJECT_LIST_QUERY = gql`
  query projects {
    projects {
      data {
        id
        name
        domains {
          name
        }
        settings
        creator {
          id
          email
        }
        team {
          id
          name
          plan {
            type
          }
        }
        partnerTrialEnd
      }
      count
    }
  }
`;

const LazyIntroVideo = lazy(() => import('./dashboard/IntroVideo'));

const ProjectList = () => {
  const [isIntroVideoOpen, setIsIntroVideoOpen] = useState(false);
  useTrackDashboardPage(PageTypes.PROJECT_LIST);
  const {
    query: { workspace: workspaceId },
  } = useRouter();
  const { user } = useDashboardAuth();

  const { loading, error, data, refetch } = useCacheQuery(PROJECT_LIST_QUERY, {
    fetchPolicy: 'no-cache',
  });
  const [deleteProject, { loading: deleteLoading }] =
    useMutation(DELETE_PROJECT);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmText, setConfirmText] = useState('');
  const [projectToDelete, setProjectToDelete] = useState<string | null>(null);

  const trialHasExpired = useIsTrialExpired();
  const partnerTrialHasExpired = (team: any, partnerTrialEnd: any) =>
    !!team &&
    team.plan.type === EXPERT &&
    partnerTrialEnd &&
    DateTime.now() > DateTime.fromJSDate(new Date(partnerTrialEnd));

  const handleCloseModal = () => {
    setShowConfirmationModal(false);
    setConfirmText('');
  };

  const onDelete = useCallback(() => {
    deleteProject({
      variables: { name: projectToDelete },
    }).then(() => {
      setProjectToDelete(null);
      handleCloseModal();
      return refetch();
    });
  }, [deleteProject, projectToDelete, refetch]);

  const workspace = useMemo(
    () => user!.teams.find((team) => team.id === workspaceId),
    [user, workspaceId],
  );

  const filteredProjects = useMemo(() => {
    if (!data?.projects) {
      return null;
    }

    if (workspaceId) {
      return data.projects.data.filter(
        (project: ProjectEntry) => project.team.id === String(workspaceId),
      );
    }

    return data.projects.data;
  }, [data, workspaceId]);

  const trialWorkspace = useMemo(() => {
    if (workspace) {
      return workspace;
    }

    return user!.teams.find((team) => team.plan.type === FREE);
  }, [user, workspace]);

  if (loading) {
    return (
      <div className="flex h-96 w-full items-center justify-center">
        <Loader size="md" className="text-blue-500" />
      </div>
    );
  }
  if (error) return <div>Error</div>;

  const { data: allProjects } = data.projects;

  if (filteredProjects.length === 0) {
    const emptyStateKey = workspaceId ? 'workspaceEmptyState' : 'emptyState';

    return (
      <div className="text-brand-darkest mx-auto flex max-w-screen-md items-center justify-center px-8 py-32 text-center sm:flex-wrap">
        <div className="lex flex-col justify-center sm:mr-0">
          <img src={EmptyStateImg} alt="empty state" className="mx-auto h-24" />
          <h1 className="mb-2 mt-8 text-2xl">
            {getText('projectList', emptyStateKey, 'title')}
          </h1>
          <p>{getText('projectList', emptyStateKey, 'subtitle')}</p>
          <Link
            to={`/new${workspace ? `?workspace=${workspace.id}` : ''}`}
            className="mx-auto mt-4 inline-block"
          >
            <Button size="lg" rounded="lg">
              {getText('projectList', emptyStateKey, 'button')}
            </Button>
          </Link>
        </div>
        <FeatureLockedRoute
          // @ts-expect-error TS(2322): Type '{ currentNumber: any; feature: keyof PlanCon... Remove this comment to see the full error message
          currentNumber={allProjects.length}
          feature={TEST_APPS}
          exact
          path="/new"
          component={NewProject}
        />
        {isIntroVideoOpen && (
          <Suspense fallback={<Loader />}>
            <LazyIntroVideo // @ts-expect-error TS(2322): Type '\{ onClose: () => void; \}' is not assignable ... Remove this comment to see the full error message
              onClose={() => setIsIntroVideoOpen(false)}
            />
          </Suspense>
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-col p-8 sm:p-2">
      <div className="flex items-center">
        <h1 className="text-2xl font-medium">
          {workspace ? workspace.name : getText('projectList.title')}
        </h1>
      </div>
      {trialWorkspace && (
        <TrialBanner plan={trialWorkspace.plan} linkIs={Link} to="/billing">
          {getText('billing.trial.compare')}
        </TrialBanner>
      )}
      <div className="text-brand mt-4 grid grid-cols-5 gap-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
        {!trialHasExpired && (
          <Link
            to={`/new${workspace ? `?workspace=${workspace.id}` : ''}`}
            className="flex w-full items-center justify-center rounded-lg border-2 border-dashed py-3 hover:border-solid hover:bg-gray-50"
          >
            <IconPlus size={20} className="mr-2 inline" />
            {getText('projectList.new')}
          </Link>
        )}
        {filteredProjects.map(
          (
            {
              domains = [],
              name,
              settings,
              creator,
              partnerTrialEnd,
              team,
            }: ProjectDefinition,
            index: any,
          ) => (
            <div
              className={classNames(
                'group w-full overflow-hidden rounded-lg border ring-pink-300 hover:ring',
              )}
              key={name}
            >
              <div className="relative flex h-full overflow-hidden">
                <Link
                  className={classNames(
                    'flex h-full w-16 flex-shrink-0 flex-col items-center justify-center py-6',
                  )}
                  style={{
                    backgroundImage: getGradientColorStops(
                      settings.theme as ThemeSettings | undefined,
                      index,
                    ),
                  }}
                  to={`/p/${name}`}
                >
                  <Popover
                    content={
                      <div
                        className="flex w-56 flex-col text-left"
                        onClick={stopPropagation}
                      >
                        <Link
                          className="px-4 py-3 text-left font-medium hover:bg-gray-100"
                          to={`/p/${name}`}
                        >
                          {getText('projectList.options.open')}
                        </Link>
                        <a
                          className="px-4 py-3 text-left font-medium hover:bg-gray-100"
                          target="_blank"
                          rel="noreferrer noopener"
                          href={getProjectDomain(name)}
                        >
                          <div className="flex items-center">
                            <span>
                              {getText('projectList.options.publishedLink')}
                            </span>
                            <IconExternalLink size={16} className="ml-2" />
                          </div>
                        </a>
                        {domains.map((domain: any) => (
                          <a
                            className="px-4 py-3 text-left font-medium hover:bg-gray-100"
                            target="_blank"
                            rel="noreferrer noopener"
                            href={`https://${domain.name}`}
                            key={domain.name}
                          >
                            <div className="flex items-center">
                              <span>{domain.name}</span>
                              <IconExternalLink size={16} className="ml-2" />
                            </div>
                          </a>
                        ))}
                        <Link
                          className="px-4 py-3 text-left font-medium hover:bg-gray-100"
                          to={`/p/${name}/_/settings`}
                        >
                          {getText('projectList.options.settings')}
                        </Link>
                        <Tooltip
                          content={getText(
                            'projectList.options.delete.tooltip',
                          )}
                          placement="right"
                          showArrow={true}
                          disabled={user?.id === creator.id}
                        >
                          <button
                            disabled={user?.id !== creator.id}
                            className={
                              'px-4 py-3 text-left font-medium hover:bg-gray-100 disabled:opacity-50'
                            }
                            onClick={() => {
                              setProjectToDelete(name);
                              return setShowConfirmationModal(true);
                            }}
                          >
                            {getText('projectList.options.delete.buttonText')}
                          </button>
                        </Tooltip>
                        {!trialHasExpired &&
                          !partnerTrialHasExpired(team, partnerTrialEnd) && (
                            <Link
                              className="px-4 py-3 text-left font-medium hover:bg-gray-100"
                              to={`/clone/${name}`}
                            >
                              {getText('projectList.options.clone')}
                            </Link>
                          )}
                        <Link
                          className="px-4 py-3 text-left font-medium hover:bg-gray-100"
                          to={`/transfer/${name}`}
                        >
                          {getText('projectList.options.transfer')}
                        </Link>
                      </div>
                    }
                    className="overflow-hidden bg-white"
                    p={0}
                    rounded="lg"
                    placement="bottom-end"
                    shadow="lg"
                    showArrow={false}
                  >
                    <div
                      onClick={stopPropagation}
                      className="absolute right-0 top-0 mr-4 mt-3 hidden h-10 w-10 items-center justify-center rounded-lg bg-slate-800 bg-opacity-50 text-gray-100 hover:bg-opacity-75 group-hover:flex"
                    >
                      <IconDotsVertical size={16} />
                    </div>
                  </Popover>
                  <span className="text-2xl text-white">
                    {getProjectAcronym(get(settings, 'title') ?? name)}
                  </span>
                </Link>
                <div className="flex h-full max-w-full flex-col justify-center overflow-hidden px-4 py-1">
                  <Link
                    className="mb-1 text-base font-medium"
                    to={`/p/${name}`}
                  >
                    {name}
                  </Link>
                  {domains.slice(0, 1).map((domain: any) => (
                    <a
                      className="text-xs text-gray-700"
                      target="_blank"
                      rel="noreferrer noopener"
                      href={`https://${domain.name}`}
                      key={domain.name}
                    >
                      <div className="flex items-center">
                        <span className="truncate">{domain.name}</span>
                        <IconExternalLink size={16} className="ml-2" />
                      </div>
                    </a>
                  ))}
                  {domains.length === 0 && (
                    <a
                      className="text-xs text-gray-700"
                      target="_blank"
                      rel="noreferrer noopener"
                      href={getProjectDomain(name)}
                    >
                      <div className="flex items-center">
                        <span className="truncate">
                          {getProjectDomain(name).replace('https://', '')}
                        </span>
                        <IconExternalLink
                          size={16}
                          className="ml-2 mr-1 flex-shrink-0"
                        />
                      </div>
                    </a>
                  )}
                  <div className="mt-1 flex items-center">
                    <IconUser
                      size={12}
                      className="mr-1 flex-shrink-0 text-gray-500"
                    />
                    <span className="truncate text-xs text-gray-700">
                      {creator.email}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ),
        )}
      </div>
      {showConfirmationModal && (
        <Modal
          icon={<IconAlertTriangle size={18} />}
          title={getText('projectList.options.delete.title')}
          closeOnOutsideClick={false}
          confirmText={
            deleteLoading ? (
              <Loader size="sm" className="text-white" />
            ) : (
              getText('projectList.options.delete.confirm')
            )
          }
          cancelText={getText('projectList.options.delete.cancel')}
          onClose={handleCloseModal}
          onCancel={handleCloseModal}
          onConfirm={onDelete}
          confirmDisabled={
            (projectToDelete &&
              confirmText !== (projectToDelete as any).trim()) ||
            deleteLoading
          }
          variant="danger"
        >
          <div className="py-1">
            {projectToDelete && (
              <div className="flex flex-col">
                <Box
                  bg={getColorShade('red', '400')}
                  className="mb-6 mt-2 flex items-center rounded-lg p-4 text-white"
                >
                  <p className="mt-2 font-medium">
                    {getText(
                      { confirmText: projectToDelete },
                      'projectList.options.delete.warningText',
                    )}
                  </p>
                </Box>
                <p className="mt-2 font-medium">
                  {getText(
                    { confirmText: projectToDelete },
                    'projectList.options.delete.confirmText',
                  )}
                </p>
                <TextInput
                  className="my-3 text-lg"
                  p={{ x: 4, y: 3 }}
                  placeholder={projectToDelete}
                  surface={LIGHT}
                  onChange={({ target: { value } }: any) =>
                    setConfirmText(value)
                  }
                  value={confirmText}
                />
              </div>
            )}
          </div>
        </Modal>
      )}
      <TemplateList />
      <FeatureLockedRoute
        // @ts-expect-error TS(2322): Type '{ currentNumber: any; feature: keyof PlanCon... Remove this comment to see the full error message
        currentNumber={allProjects.length}
        feature={TEST_APPS}
        exact
        path="/new"
        component={NewProject}
      />
    </div>
  );
};

export default ProjectList;
